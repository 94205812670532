import React, { useState } from "react";
import { FaRegCopy } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const JobDetails = ({ job }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  const copyToClipboard = () => {
    if (job?.description) {
      navigator.clipboard.writeText(job.description).then(
        () => {
          toast.success(`Copied Job Description!`, { position: "top-right" });
          setTimeout(() => {
            navigate("/matchwithjd");
          }, 2000); // 2000ms = 2 seconds
        },
        (err) => {
          console.error("Failed to copy: ", err);
        }
      );
    }
  };

  const handleApplyClick = () => {
    setShowModal(true); // Show modal when Apply is clicked
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleMatchFirst = () => {
    copyToClipboard(); // Copy the job description to clipboard
    closeModal();
    navigate("/matchwithjd"); // Redirect to the resume matcher page
  };

  return (
    <div>
      <section className="px-4 flex flex-col rounded-r-3xl">
        {/* Job details */}
        <div className="flex justify-between items-center h-48 border-b-2 mb-8">
          <div className="flex space-x-4 items-center">
            <div className="h-12 w-12 rounded-full overflow-hidden">
              <img
                src="https://cdn.pixabay.com/photo/2017/10/31/09/55/dream-job-2904780_1280.jpg"
                alt="Company Logo"
                loading="lazy"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="flex flex-col">
              <h3 className="font-semibold text-lg">
                {job?.company.display_name}
              </h3>
              <p className="font-bold text-xl text-gray-800">{job?.title}</p>
              <p className="text-sm text-gray-400">
                {job?.location.display_name}
              </p>
            </div>
          </div>
        </div>

        <section>
          <h1 className="font-bold text-2xl">Job Description</h1>
          <article className="mt-8 text-gray-600 leading-7 tracking-wider">
            <p>{job?.description}</p>
          </article>
        </section>

        <div className="mt-8 flex space-x-4">
          <button
            onClick={handleApplyClick}
            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
          >
            Apply
          </button>

          <div
            className={`inline-flex items-center border-gray-200 border p-1 rounded-lg bg-[#F9FAFB] cursor-pointer hover:bg-green-100`}
            onClick={copyToClipboard}
          >
            <p className="text-gray-500">Copy Job Description</p>
          </div>
        </div>
      </section>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-center">
              Matched Your Resume with Job Description?
            </h2>
            <p className="text-sm text-center text-gray-600 mb-6">
              (90% of resumes get rejected due to skills mismatch, so be that
              10%)
            </p>
            <div className="flex justify-between">
              <button
                onClick={() => {
                  closeModal();
                  window.open(job?.redirect_url, "_blank"); // Open the job URL in a new tab
                }}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
              >
                Ready to Apply
              </button>

              <button
                onClick={handleMatchFirst}
                className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
              >
                Match Resume First
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default JobDetails;
