import React, { useEffect, useRef, useState } from "react";
import img1 from "../images/Designer6.png";
import { FaRegCircleUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./Header.css"; // Assuming you're using an external CSS file for the animation

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("email");
    Cookies.remove("userName");
    Cookies.remove("formSubmitted");
    navigate("/login");
  };

  const userButtonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);

  useEffect(() => {
    if (userButtonRef.current) {
      setButtonWidth(userButtonRef.current.offsetWidth);
    }
  }, []);

  const handleAnswerNowClick = () => {
    const email = Cookies.get("email");
    if (!email) {
      navigate("/login"); // Redirect to login if email is not present
    } else {
      navigate("/query-form"); // Proceed to query form
    }
  };

  return (
    <>
      <div className="blinking-whole text-center py-1">
        <p className="text-sm flex justify-center md:text-base font-mono font-bold">
          <div className="flex mr-4">
            🎉 Any Issues ? Earn FREE credits by Telling us Your Issues! 💬
          </div>

          <span
            onClick={handleAnswerNowClick}
            className="blinking-arrow flex items-center text-blue-600 cursor-pointer"
          >
            👉
            <p className="blinking-text  hover:underline ml-2">
              Answer Now 🚀{" "}
            </p>
          </span>
        </p>
      </div>

      <header className="text-gray-600 body-font">
        <div className="container flex justify-between pt-7 px-2 flex-col md:flex-row items-center">
          <Link
            to="/"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <img
              src={img1}
              alt="MatchMyResume Logo"
              className="w-12 h-12 expand"
            />
            <span className="ml-3 font-semibold expand hover:text-green-400 text-xl">
              MatchMyResume
            </span>
          </Link>
          <div className="flex space-x-4">
            <nav className="md:ml-auto flex space-x-4 flex-wrap items-center text-base justify-center">
              <Link
                to="/matchwithjd"
                className="inline-flex items-center expand cursor-pointer font-semibold p-1 rounded-lg"
              >
                <h1 className="hover:text-green-400">Resume Matcher</h1>
              </Link>
              <Link
                to="/resumeenhance"
                className="inline-flex items-center expand cursor-pointer font-semibold p-1 rounded-lg"
              >
                <h1 className="hover:text-green-400">Resume Enhancer</h1>
              </Link>
              <Link
                to="/resumehistory"
                className="inline-flex items-center expand cursor-pointer font-semibold p-1 rounded-lg"
              >
                <h1 className="hover:text-green-400">Resume History</h1>
              </Link>
              <Link
                to="/job-apply"
                className="inline-flex items-center expand cursor-pointer font-semibold p-1 rounded-lg"
              >
                <h1 className="hover:text-green-400 relative">
                  Jobs
                  <span className=" top-0  bg-red-500 text-white text-xs font-bold rounded-full px-2 py-[1px] ml-1">
                    NEW
                  </span>
                </h1>
              </Link>
              <Link
                to="/buycredits"
                className="inline-flex items-center expand cursor-pointer font-semibold p-1 rounded-lg"
              >
                <h1 className="hover:text-green-400">Buy Credits ⚡️</h1>
              </Link>
              <Link
                to="/contactus"
                className="inline-flex items-center expand cursor-pointer font-semibold p-1 rounded-lg"
              >
                <h1 className="hover:text-green-400">Contact Us</h1>
              </Link>
              {Cookies.get("userName") ? (
                <div className="relative inline-block text-left">
                  <button
                    ref={userButtonRef}
                    onClick={handleDropdownToggle}
                    className="inline-flex items-center ml-4 bg-gray-100 border-0 py-2 px-3 focus:outline-none hover:bg-gray-200 rounded-2xl text-base mt-4 md:mt-0"
                  >
                    <FaRegCircleUser className="mr-2" />
                    {Cookies.get("userName")?.split(" ")[0]}{" "}
                  </button>

                  {dropdownOpen && (
                    <div
                      className="origin-top-right absolute right-0 mt-2 rounded-xl shadow-lg hover:bg-red-300 ring-1 ring-black ring-opacity-5"
                      style={{ width: buttonWidth }}
                    >
                      <div className="py-1">
                        <button
                          onClick={handleLogout}
                          className="block w-full z-10 text-left px-4 py-2 text-sm text-red-700 rounded-xl"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  to="/signup"
                  className="inline-block bg-gray-200 mx-2 py-2 px-3 text-center text-sm rounded-2xl font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-green-200 expand focus-visible:ring active:text-gray-700 md:text-base"
                >
                  Sign Up
                </Link>
              )}
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
