import React, { useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { deleteResume } from "../../services/GetResumeService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img from "../../images/resume1.png";
import Loading from "../Loading";
const ResumeCard = ({ link, name, time, setResumes, id }) => {
  const formattedDate = new Date(time).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const [loading, setloading] = useState(false);
  const handleDelete = async (id) => {
    // toast.success("Resume Deleted successfully", {
    //   position: "top-right",
    // });
    setloading(true);
    try {
      await deleteResume(id);
      setResumes((prevResumes) =>
        prevResumes.filter((resume) => resume._id !== id)
      );
      setloading(false);
    } catch (error) {
      console.error("Failed to delete resume:", error);
      setloading(false);
    }
  };
  useEffect(() => {
    if (loading) {
      toast.error("Resume Deleted successfully", {
        position: "top-right",
      });
    }
  }, [loading]);
  return (
    <div className="flex flex-col items-center gap-4 md:flex-row lg:gap-6">
      {loading && <Loading work="Deleting" />}
      <div className="group relative block   shrink-0 self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40">
        <img
          src={img}
          loading="lazy"
          alt="Photo by @heydevn"
          className="w-80 object-fill   border-none"
        />
      </div>

      <div className="flex flex-col gap-2">
        <span className="text-sm text-gray-400">{formattedDate}</span>
        <h2 className="text-xl font-bold text-gray-800">
          <a
            href={link}
            className="transition duration-100 hover:text-indigo-500 active:text-indigo-600"
          >
            {name}
          </a>
        </h2>

        <div className="flex flex-row space-x-3">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row w-20 cursor-pointer  justify-center items-center bg-blue-200 space-x-1 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-2 border-2 border-blue-200 hover:border-transparent rounded"
          >
            <span>View</span>
            <FiEye />
          </a>
          <button
            onClick={() => handleDelete(id)}
            className="flex flex-row w-20  justify-center items-center bg-red-200 space-x-1 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-1 border-2 border-red-200  hover:border-transparent rounded"
          >
            <span>Delete</span>
            <MdDeleteOutline />
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResumeCard;
