import React, { useEffect, useRef, useState } from "react";
import { preJDData } from "../Demo/Skills";

const MatchText = ({ setJdData, setOptionsData, page }) => {
  const textareaRef = useRef(null);

  const [charCount, setCharCount] = useState(0);
  const maxChars = 2500;

  const handleTextareaChange = (event) => {
    let jdText = event.target.value;
    if (jdText.length <= maxChars) {
      setJdData(jdText);
      setCharCount(jdText.length);
    } else {
      jdText = jdText.slice(0, maxChars); // Trim to maxChars if necessary
      setJdData(jdText);
      textareaRef.current.value = jdText;
      setCharCount(maxChars);
    }
  };

  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
  const [selectedFirstOption, setSelectedFirstOption] =
    useState("Select an option");
  const [selectedSecondOption, setSelectedSecondOption] =
    useState("Select an option");

  const toggleFirstDropdown = () => {
    setIsFirstDropdownOpen(!isFirstDropdownOpen);
    if (!isFirstDropdownOpen) {
      setIsSecondDropdownOpen(false);
    }
  };

  const toggleSecondDropdown = () => {
    setIsSecondDropdownOpen(!isSecondDropdownOpen);
    if (!isSecondDropdownOpen) {
      setIsFirstDropdownOpen(false);
    }
  };

  const handleFirstOptionClick = (option) => {
    setSelectedFirstOption(option);
    setIsFirstDropdownOpen(false);
  };

  const handleSecondOptionClick = (option) => {
    setSelectedSecondOption(option);
    setIsSecondDropdownOpen(false);
  };

  const generateText = () => {
    const combinedOption = `${selectedFirstOption} ${selectedSecondOption}`;
    // setOptionsData(combinedOption);
    const roleData = preJDData.find((item) => item.role === combinedOption);

    if (roleData) {
      return roleData.description.requirements.join("\n");
    }
    return "No data available for the selected options.";
  };
  const handleGenerate = () => {
    const text = generateText();

    if (page === "matchJd") {
      textareaRef.current.value = text;
      setJdData(text);
      setCharCount(text.length);
    }
  };
  useEffect(() => {
    if (page != "matchJd") {
      const combinedOption = `${selectedFirstOption} ${selectedSecondOption}`;
      setOptionsData(combinedOption);
    }
  }, [selectedFirstOption, selectedSecondOption]);
  return (
    <div
      className={`flex flex-col items-center  ${
        page !== "matchJd" ? "justify-evenly" : "justify-between"
      }`}
    >
      {page === "matchJd" ? (
        <div className="flex">
          <h1 className="text-2xl font-semibold mb-2">Add Job Description </h1>
        </div>
      ) : (
        <div className="flex">
          <h1 className="text-2xl font-semibold mb-2">
            Select Your Job Role and Experience{" "}
          </h1>
        </div>
      )}

      <div className="max-w-2xl">
        <label
          htmlFor="message"
          className="block  text-sm font-medium text-gray-900 dark:text-gray-400"
        ></label>
        {page === "matchJd" && (
          <p className="mb-2 px-2 text-sm py-1 rounded-xl bg-[#e6eaa8]">
            Add the Job Description of the job you wanted to match your resume
            with .
          </p>
        )}

        <div className="mb-2 relative flex gap-4">
          <div>
            <button
              id="dropdownDefaultButton1"
              onClick={toggleFirstDropdown}
              className={`text-white ${
                page == "matchJd"
                  ? "bg-blue-700 hover:bg-blue-800"
                  : "bg-green-700 hover:bg-green-800"
              }  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center`}
              type="button"
            >
              {selectedFirstOption}
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            {isFirstDropdownOpen && (
              <div
                id="dropdown1"
                className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton1"
                >
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Full Stack")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Full Stack
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Backend")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Backend
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Front End")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Front End
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Blockchain")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Blockchain
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Gaming")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Gaming
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("iOS")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      iOS
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Android")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Android
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Data Analyst")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Data Analyst
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("DevOps")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      DevOps
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("Site Reliability")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Site Reliability
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleFirstOptionClick("QA Testing")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      QA Testing
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div>
            <button
              id="dropdownDefaultButton2"
              onClick={toggleSecondDropdown}
              className={`text-white ${
                page == "matchJd"
                  ? "bg-blue-700 hover:bg-blue-800"
                  : "bg-green-700 hover:bg-green-800"
              }  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center`}
              type="button"
            >
              {selectedSecondOption}
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            {isSecondDropdownOpen && (
              <div
                id="dropdown2"
                className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton2"
                >
                  <li>
                    <button
                      onClick={() => handleSecondOptionClick("Intern")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Intern
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSecondOptionClick("Junior")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Junior
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSecondOptionClick("Mid")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Mid
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSecondOptionClick("Senior")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Senior
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSecondOptionClick("Lead")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Lead
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSecondOptionClick("Manager")}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Manager
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div>
            <button
              className={`w-full ${
                page != "matchJd" && "hidden"
              } text-black font-normal bg-green-200 hover:bg-green-300 cursor-pointer  rounded-lg text-sm px-5 py-2.5`}
              type="submit"
              onClick={handleGenerate}
            >
              Generate
            </button>
          </div>
        </div>

        <textarea
          id="message"
          rows={4}
          ref={textareaRef}
          className={`block p-2.5 w-full text-sm ${
            page !== "matchJd" && "hidden"
          } text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500`}
          placeholder="Your message..."
          onChange={handleTextareaChange}
        />
        {page === "matchJd" && (
          <p className="text-sm text-gray-500 mt-1">
            {charCount}/{maxChars} characters
          </p>
        )}
      </div>
    </div>
  );
};

export default MatchText;
