// import React, { useEffect } from "react";

// const JobSideBar = ({ jobs, setSelectedJob, fetchMoreJobs, hasMore }) => {
//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 &&
//         hasMore
//       ) {
//         fetchMoreJobs(); // Fetch more jobs when scrolled to bottom
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [hasMore, fetchMoreJobs]);

//   return (
//     <div>
//       <section className="flex flex-col pt-3 pr-2 border-r-2 h-full">
//         <ul className="mt-6">
//           {jobs.map((job) => (
//             <li
//               key={job.id}
//               className="py-5 border-b px-3 transition hover:bg-indigo-100 cursor-pointer"
//               onClick={() => setSelectedJob(job)} // Handle job click
//             >
//               <div className="flex justify-between items-center">
//                 <h3 className="text-lg font-semibold">{job.title}</h3>
//               </div>
//               <div className="text-md italic text-gray-500">
//                 {job.company.display_name}
//               </div>
//             </li>
//           ))}
//         </ul>
//       </section>
//     </div>
//   );
// };

// export default JobSideBar;

import React, { useEffect, useRef } from "react";

const JobSideBar = ({
  jobs,
  fetchMoreJobs,
  hasMore,
  loading,
  setSelectedJob,
  onJobClick,
}) => {
  const jobSidebarRef = useRef(null);

  const handleScroll = () => {
    const sidebar = jobSidebarRef.current;
    if (
      sidebar.scrollTop + sidebar.clientHeight >= sidebar.scrollHeight - 10 &&
      hasMore &&
      !loading
    ) {
      fetchMoreJobs(); // Fetch more jobs when the user scrolls to the bottom
    }
  };

  useEffect(() => {
    const sidebar = jobSidebarRef.current;
    if (sidebar) {
      sidebar.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, loading]);

  return (
    <div ref={jobSidebarRef} className="overflow-y-scroll h-[80vh]">
      <section className="flex flex-col pt-3 pr-2 border-r-2 h-full">
        <ul className="mt-6">
          {jobs.map((job) => (
            <li
              key={job.id}
              className="py-5 border-b px-3 transition hover:bg-indigo-100 cursor-pointer"
              onClick={() => onJobClick(job)}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">{job.title}</h3>
              </div>
              <div className="text-md italic text-gray-500">
                {job.company.display_name}
              </div>
            </li>
          ))}
        </ul>
      </section>
      {loading && <p>Loading more jobs...</p>}
      {!hasMore && <p>No more jobs available.</p>}
    </div>
  );
};

export default JobSideBar;
