import React from "react";
import img1 from "../../images/Designer1.png";
import { Link } from "react-router-dom";
const FeatureHomepage = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="lg:w-1/2 w-full justify-center mb-10 lg:mb-0 rounded-lg overflow-hidden flex items-center">
          <img
            alt="feature"
            className="object-cover object-center rounded-3xl h-2/3 w-2/3 expand"
            src={img1}
          />
        </div>

        <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Smart Matching:
              </h2>
              <p className="leading-relaxed text-base">
                Upload your resume and job description, and our intelligent
                algorithms will analyze them to find the best fit. No more
                guesswork—get personalized recommendations tailored to your
                skills and experience.
              </p>
              <Link
                to={"/matchwithjd"}
                className="mt-3  cursor-pointer text-indigo-500 inline-flex items-center expand"
              >
                Try Now ⚡️
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2 transition-transform duration-300 ease-in-out transform hover:scale-110"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <circle cx={6} cy={6} r={3} />
                <circle cx={6} cy={18} r={3} />
                <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12" />
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Experience and Domain Filters:
              </h2>
              <p className="leading-relaxed text-base">
                Customize your search by selecting your preferred experience
                level (entry-level, mid-career, or senior) and domain (IT,
                finance, healthcare, etc.). We’ll narrow down the results to
                match your expertise.
              </p>
              <Link
                to={"/resumeenhance"}
                className="mt-3 cursor-pointer text-indigo-500 inline-flex items-center expand"
              >
                Try Now ⚡️
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2 transition-transform duration-300 ease-in-out transform hover:scale-110"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-5">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Resume Optimization:
              </h2>
              <p className="leading-relaxed text-base">
                Not getting callbacks? Our platform provides tips and
                suggestions to enhance your resume. From formatting to keywords,
                we’ve got you covered.
              </p>
              <Link
                to={"/resumehistory"}
                className="mt-3 cursor-pointer text-indigo-500 inline-flex items-center expand"
              >
                Try Now ⚡️
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2 transition-transform duration-300 ease-in-out transform hover:scale-110"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureHomepage;
