import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  jobOptions,
  salaryOptions,
  locationOptions,
  jobTypeOptions,
} from "../Demo/Skills.js";
import JobSideBar from "./JobSideBar.js";
import JobDetails from "./JobDetails.js";
import axios from "axios";
import Loading from "../Loading.js";
import Page404 from "../Page404.js";

const JobApply = () => {
  const [job, setJob] = useState(JSON.parse(localStorage.getItem("job")) || []);
  const [location, setLocation] = useState(
    JSON.parse(localStorage.getItem("location")) || []
  );
  const [minSalary, setMinSalary] = useState(
    JSON.parse(localStorage.getItem("minSalary")) || []
  );
  const [jobType, setJobType] = useState(
    JSON.parse(localStorage.getItem("jobType")) || []
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [jobs, setJobs] = useState(
    JSON.parse(localStorage.getItem("jobs")) || []
  );
  const [selectedJob, setSelectedJob] = useState(
    JSON.parse(localStorage.getItem("selectedJob")) || null
  );
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isIndianJobs, setIsIndianJobs] = useState(true); // New state for toggle

  // Fetch jobs
  const fetchJobs = async (page) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        // "http://localhost:8080/api/jobs/fetchIndianJobs",
        "https://matchmyresumebackend.vercel.app/api/jobs/fetchIndianJobs",
        {
          jobTitle: job,
          location: location,
          minSalary: minSalary,
          jobType: jobType,
          pageNumber: page,
        }
      );

      if (data.length === 0) {
        setHasMore(false);
      } else {
        setJobs((prevJobs) => [...prevJobs, ...data]); // Append new jobs to the list
        setPageNumber(page + 1); // Increment page number

        if (page === 1 && data.length > 0) {
          setSelectedJob(data[0]); // Set the first job by default on the first page
        }
      }
    } catch (error) {
      console.error("Error fetching jobs", error);
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    setJobs([]); // Reset jobs list
    setPageNumber(1); // Reset page number
    fetchJobs(1); // Fetch jobs from page 1
  };

  const animatedComponents = makeAnimated();
  const customCss = {
    control: (base) => ({
      ...base,
      minHeight: 35,
      backgroundColor: "#F2F2F2",
      borderRadius: 10,
      border: "none",
      outline: "none",
      transition: "border-color 0.3s ease",
    }),
  };

  // Persist filters and jobs in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("job", JSON.stringify(job));
    localStorage.setItem("location", JSON.stringify(location));
    localStorage.setItem("minSalary", JSON.stringify(minSalary));
    localStorage.setItem("jobType", JSON.stringify(jobType));
    localStorage.setItem("jobs", JSON.stringify(jobs));
    localStorage.setItem("selectedJob", JSON.stringify(selectedJob));
  }, [job, location, minSalary, jobType, jobs, selectedJob]);

  // Detect screen resize for mobile responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Detect screen width below 1024px
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on first render

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle job click
  const handleJobClick = (job) => {
    setSelectedJob(job);
    if (isMobile) {
      setShowModal(true); // Show modal on mobile when a job is clicked
    }
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false); // State to handle filter dropdown for mobile

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleToggleJobs = () => {
    setIsIndianJobs(!isIndianJobs); // Toggle between Indian and Remote Abroad Jobs
  };

  return (
    <div>
      {loading && <Loading work="Logging In" />}
      <div>
        {/* Toggle Button */}
        <div className="text-center mb-4">
          <button
            onClick={handleToggleJobs}
            className="bg-indigo-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
          >
            {isIndianJobs
              ? "Switch to Remote Abroad Jobs"
              : "Switch to Indian Jobs"}
          </button>
        </div>

        {/* Only show filters and jobs when showing Indian jobs */}
        {isIndianJobs ? (
          <>
            <div className="block md:hidden">
              <button
                onClick={toggleFilter}
                className=" text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                {isFilterOpen ? "Hide Filters" : "Show Filters"}
              </button>
            </div>

            <div
              className={`${
                isFilterOpen ? "block" : "hidden"
              } md:flex flex-col md:flex-row justify-around flex-wrap gap-4 p-4`}
            >
              {/* Filter Form */}
              <div className="w-full md:w-1/6">
                <label className="block mb-2 text-gray-700">Job</label>
                <Select
                  isMulti
                  components={makeAnimated()}
                  styles={customCss}
                  options={jobOptions}
                  onChange={(selected) => setJob(selected)}
                  value={job}
                />
              </div>

              <div className="w-full md:w-1/6">
                <label className="block mb-2 text-gray-700">Location</label>
                <Select
                  isMulti
                  components={makeAnimated()}
                  styles={customCss}
                  options={locationOptions}
                  onChange={(selected) => setLocation(selected)}
                  value={location}
                />
              </div>

              <div className="w-full md:w-1/6">
                <label className="block mb-2 text-gray-700">Min Salary</label>
                <Select
                  components={makeAnimated()}
                  styles={customCss}
                  options={salaryOptions}
                  onChange={(selected) => setMinSalary(selected)}
                  value={minSalary}
                />
              </div>

              <div className="w-full md:w-1/6">
                <label className="block mb-2 text-gray-700">Type</label>
                <Select
                  components={makeAnimated()}
                  styles={customCss}
                  options={jobTypeOptions}
                  onChange={(selected) => setJobType(selected)}
                  value={jobType}
                />
              </div>

              <div className="w-full  md:w-1/6">
                {/* //flex items-end */}
                <label className="block mb-2 text-gray-700 opacity-0 ">
                  Type
                </label>
                <button
                  onClick={handleSubmit}
                  className="bg-white border-2 py-2 hover:border-[#9a8def] hover:text-[#9a8def] px-8 border-[#7460ff] rounded-lg text-[#7460ff]"
                >
                  Get Result
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center mt-10">
            <h1 className="text-3xl font-bold text-gray-700">
              Remote Abroad Jobs
            </h1>
            <p className="text-lg text-gray-500 mt-4">Coming Soon...</p>
          </div>
        )}

        {isIndianJobs && (
          <>
            {jobs && jobs.length > 0 ? (
              <main className="flex w-full h-[80vh] rounded-3xl">
                <div
                  className={`w-full ${
                    !isMobile ? "md:w-1/4" : ""
                  } overflow-y-scroll`}
                >
                  <JobSideBar
                    jobs={jobs}
                    setSelectedJob={setSelectedJob}
                    fetchMoreJobs={() => fetchJobs(pageNumber)}
                    hasMore={hasMore}
                    loading={loading}
                    onJobClick={handleJobClick}
                  />
                </div>
                {!isMobile && selectedJob && (
                  <div className="w-3/4 overflow-y-scroll">
                    <JobDetails job={selectedJob || jobs[0]} />
                  </div>
                )}
              </main>
            ) : (
              <Page404
                main="No Jobs Found"
                desc="Your search doesn't have any jobs, please search to get jobs"
                page="jobapply"
              />
            )}
            {isMobile && showModal && selectedJob && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-4 rounded-lg max-w-lg w-full">
                  <button
                    className="text-red-500 float-right"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <JobDetails job={selectedJob} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JobApply;
