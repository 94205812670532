import React from "react";
import Header from "../components/Header";
import Hero from "../components/IntroPage/Hero";
import FeatureHomepage from "../components/IntroPage/FeatureHomepage";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <FeatureHomepage />
    </div>
  );
};

export default HomePage;
