import React, { useEffect, useState } from "react";
import ResumeUpload from "../matchresume/ResumeUpload";
import MatchText from "../matchresume/MatchText";
import axios from "axios";
import { marked } from "marked";
import "./ResumeRoleAi.css";
import {
  decreaseAiCredits,
  fetchCredits,
} from "../../services/GetResumeService";
import Loading from "../Loading";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Cookies from "js-cookie";
import Tour from "reactour";
import { toast, ToastContainer } from "react-toastify";

const ResumeRoleAi = () => {
  const [resumeData, setResumeData] = useState("");
  const [OptionsData, setOptionsData] = useState("");
  const [suggestions, setSuggestions] = useState("");
  const [aicredits, setaicredits] = useState(0);
  const [loading, setloading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const getSuggestions = async () => {
    if (
      !OptionsData ||
      OptionsData.trim() === "" ||
      OptionsData.includes("Select an option")
    ) {
      toast.error(
        "Please fill out the role options first before generating suggestions.",
        {
          position: "top-right",
        }
      );
      return; // Exit early if OptionsData is invalid
    }

    if (!resumeData) {
      toast.error("Please upload Resume first before generating suggestions.", {
        position: "top-right",
      });
      return; // Exit early if OptionsData is empty
    }
    const prompt = `This is the resume data ${resumeData} and this is the role I am looking for ${OptionsData}. Now give suggestions about what I should change in my resume to make it better`;
    if (aicredits <= 0) {
      toast.error(
        "No AI credits left. Please purchase more credits to continue.",
        {
          position: "top-right",
        }
      );
      return;
    }
    console.log(OptionsData, "from 52");

    setloading(true);
    try {
      const response = await axios.post(
        "https://matchmyresumebackend.vercel.app/api/gemini/generate",
        { prompt }
      );
      setSuggestions(response.data.content);
      setShowSuggestions(true);

      try {
        const updatedCredits = await decreaseAiCredits(Cookies.get("token"));
        console.log(
          "Credits updated successfully. Remaining credits:",
          updatedCredits
        );
        setaicredits(updatedCredits);
      } catch (error) {
        console.error("Failed to decrease credits:", error);
      }

      setloading(false);
    } catch (error) {
      console.error("Error getting suggestions:", error);
      setloading(false);
    }
  };
  useEffect(() => {
    const getCredits = async () => {
      try {
        const initialCredits = await fetchCredits(Cookies.get("token"));
        setaicredits(initialCredits.aiCredits);
      } catch (error) {
        console.error("Failed to fetch credits:", error);
      }
    };

    getCredits();
  }, [Cookies.get("token")]);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const steps = [
    {
      selector: ".my-first-step",
      content: "First Sign-up and then Upload Your resume here",
    },
    {
      selector: ".my-second-step",
      content: "select desired domain and experience",
    },
    {
      selector: ".my-third-step",
      content: "Press here to get personalized ai suggestion",
    },
  ];

  return (
    <div>
      {loading && <Loading work="loading" />}
      <div className="flex justify-between">
        <div className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Credits Left {aicredits} ⚡️
          </span>
        </div>
        <button
          onClick={() => setIsTourOpen(true)}
          className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-black focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800"
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 dark:bg-white rounded-md group-hover:bg-opacity-0">
            Take Tour ⚡️
          </span>
        </button>
      </div>

      <div className="flex flex-col">
        <h1 className="font-bold text-2xl sm:text-3xl mb-8 text-center">
          Resume Role AI
        </h1>
        <div className="flex flex-col md:flex-row justify-around items-center">
          <div className="my-first-step">
            <ResumeUpload setResumeData={setResumeData} />
          </div>
          <div className="my-second-step">
            <MatchText
              setJdData={""}
              setOptionsData={setOptionsData}
              page="roleai"
            />
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="my-third-step bg-white w-[70%] sm:w-[50%] md:w-[18%] border-2 my-2 py-2 hover:border-[#9a8def] hover:text-[#9a8def] px-8 border-[#7460ff] rounded-lg text-[#7460ff]"
            onClick={getSuggestions}
          >
            Get Suggestions
          </button>
        </div>
      </div>

      {suggestions && (
        <>
          {showSuggestions && (
            <div
              className="suggestions border-2 border-[#c7beff] bg-[#202A44] rounded-xl p-4 text-[#edecd5] mt-6"
              dangerouslySetInnerHTML={{ __html: marked(suggestions) }}
            />
          )}
          <div className="flex justify-center mt-4">
            <button
              onClick={() => setShowSuggestions(!showSuggestions)}
              className="text-3xl sm:text-4xl hover:animate-ping"
            >
              {showSuggestions ? <FiChevronUp /> : <FiChevronDown />}
            </button>
          </div>
        </>
      )}

      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <ToastContainer />
    </div>
  );
};

export default ResumeRoleAi;
